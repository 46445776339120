import {http} from '@/utils/http'
export default {
    getStoreDetail(params: string | string[] | null){
        // 获取店铺详情
        return http({
            url:`/members/api/v1/shops/${params}`,
            method:'get',
            params:params
        })
    },
    collectShop(params: string){
        // 收藏店铺
        return http({
            url:`/members/api/v1/shops/${params}/collection`,
            method:'post'
        })
    },
    cancleCollect(params: string){
        // 收藏店铺
        return http({
            url:`/members/api/v1/shops/${params}/collection`,
            method:'delete'
        })
    },
    getStoreKind(params: object){
        // 获取店铺分类
        return http({
            url:`/members/api/v1/classification`,
            method:'get',
            params:params
        })
    },
    getStoreGoods(params: object){
        // 获取店铺商品
        return http({
            url:`/members/api/v1/merchandise`,
            method:'get',
            params:params
        })
    },
    submitOrder(params: object){
        // 下单
        return http({
            url:`/members/api/v1/orders`,
            method:'post',
            data:params
        })
    },
}