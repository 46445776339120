

































































































































import { Vue, Component, Provide } from "vue-property-decorator";
import serve from "@/serve/store";
import { Toast } from "vant";
@Component({})
export default class Store extends Vue {
  tabData: Array<object> = [];
  page = 0;
  listData: Array<object> = [];
  storeDetail: object = {};
  goodsId = "";
  showDia = false;
  QQdata = [];
  @Provide() path = this.$store.state.headerPath;
  @Provide() active = "";
  @Provide() rate = 3.5;
  @Provide() show = false;
  @Provide() cover = require("@/assets/img/cover.jpg");
  @Provide() goods = require("@/assets/img/goods.jpg");
  @Provide() loading = false; //是否处于加载状态
  @Provide() finished = false; //是否已加载完所有数据
  @Provide() isLoading = false; //是否处于下拉刷新状态
  created() {
    this.getKind();
    this.getDetail();
  }
  private goBack(): void {
    this.$router.go(-1);
  }
  private goTo(val: string): void {
    this.$router.push(val);
  }
  private tabChange(): void {
    this.listData = [];
    this.page = 0;
    this.getData();
  }
  chooseQQ(val: object): void {
    this.QQdata = val["qq"];
    this.showDia = true;
  }
  openQQ(val?: string): void {
    window.location.href = `mqqwpa://im/chat?chat_type=wpa&uin=${val}&version=1&src_type=web&web_src=oicqzone.com`;
  }
  async getDetail() {
    const res = await serve.getStoreDetail(this.$route.query.id.toString());
    this.storeDetail = res.data.data;
  }
  async getData(val?: string) {
    if (val) {
      this.active = val;
    }
    const data = {
      id: this.$route.query.id,
      classId: this.active,
      // sortString: "prices",
      page: this.page,
      size: 5,
    };
    const res = await serve.getStoreGoods(data);
    res.data.data.content.map((item) => {
      item.pic = item.pictures.split(",");
      this.listData.push(item);
    });
    // this.listData = this.listData.concat(res.data.data.content);
    this.isLoading = false;
    if (res.data.data.totalElements <= this.listData.length) {
      this.finished = true;
    } else {
      this.finished = false;
    }
    this.loading = false;
  }
  async collect(id: string) {
    const res = await serve.collectShop(id);
    if (res.data.code === 0) {
      Toast("收藏成功");
      this.getDetail();
    } else {
      Toast(res.data.message);
    }
  }
  async cancelCollect(id: string) {
    const res = await serve.cancleCollect(id);
    if (res.data.code === 0) {
      Toast("取消收藏成功");
      this.getDetail();
    } else {
      Toast(res.data.message);
    }
  }
  async getKind() {
    const res = await serve.getStoreKind({ id: this.$route.query.id });
    if (res.data.code === 0) {
      this.tabData = res.data.data;
      // this.active = res.data.data[0].id
      // this.getData(res.data.data[0].id);
    }
  }
  private onLoad(): void {
    // 上拉加载
    this.loading = true;
    this.getData();
    this.page++;
  }

  private onRefresh(): void {
    //下拉刷新
    this.listData = [];
    this.isLoading = true;
    this.page = 0;
    this.getData();
  }
  private order(item: object): void {
    this.goodsId = item["id"];
    this.show = true;
  }
  async defineOrder() {
    const data = {
      merchandiseId: this.goodsId,
    };
    const res = await serve.submitOrder(data);
    if (res.data.code === 0) {
      Toast.success("下单成功");
    } else {
      Toast.fail(res.data.message);
    }
  }
}
